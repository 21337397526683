import { Grid, Typography, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  Call,
  Email,
  Face,
  Sms,
  MailOutline,
  Print,
  Computer,
  CalendarToday,
} from '@mui/icons-material';
import { updateTaskProperties } from 'actions/action-tasks';
import { addNoteCommunication } from 'actions/action-notes';
import { addPatient, fetchPatient, selectPatientTab } from 'actions/action-patient';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDateTimePicker, renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderDropdown, renderReactRadioGroup } from 'components/form/field/redux-field';
import { required, validateDate, validateDateTime } from 'components/form/validation/validation';
import { COMMUNICATION_FORM, COMMUNICATION_FORM_PHONE_CALL, PATIENT } from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { handleNullString } from 'services/utils/formatting';
import { translateUsers } from 'services/utils/users-service';
import { change, Field, formValueSelector, reduxForm, getFormValues } from 'redux-form';
import { sendSMS, validatePHI } from 'actions/action-communication';
import {
  otherCommunicationOptions,
  noteTagTypes,
  noteCommunicationResults,
  communicationIngoingOutgoing,
  NOTE_TYPES,
} from 'constants/lists';
import { getTodayDateTime } from 'services/utils/date-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { parseNoteToDb } from 'services/utils/note-service';
import { CommunicationType } from 'constants/enums';
import { Conversation } from 'components/sms/conversation/conversation';
import { PhonePopup } from 'components/phone/phone-popup';
import { SendForm } from 'components/sms/send-form/send-form';
import { styles } from './note-styles';
import NoteArea from './NoteArea';
import colors from '../../lib/themes/colors.module.scss';

const { deepBlue2 } = colors;

class CommunicationForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handlePreferred = this.handlePreferred.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handlePreferred(type, key) {
    const { change } = this.props; // eslint-disable-line
    change(type, key);
  }

  handleTabChange(event, tabValue) {
    const { change } = this.props; // eslint-disable-line
    change('tabValue', tabValue);
  }

  async handleSubmit(values) {
    const newValues = parseNoteToDb(values);
    const {
      addNoteCommunication, // eslint-disable-line
      patient,
      tagTypeId,
      tagResourceId,
      reset,
      cancelCallback,
      task,
      updateTaskProperties, // eslint-disable-line
      embeddedFormId,
      phoneNoteTags,
      ongoingPhoneCall,
    } = this.props;
    const tags = [
      {
        tag_type_id: tagTypeId || phoneNoteTags.tagTypeId,
        resource_id: tagResourceId || phoneNoteTags.tagResourceId,
      },
    ];
    const communication = {
      communication_type_id: ongoingPhoneCall
        ? CommunicationType.Phone
        : values.communication_type_id,
      is_incoming: ongoingPhoneCall ? '0' : values.is_incoming,
      is_patient: 0,
      is_action_required:
        Number(values.communication_type_id) === CommunicationType.Phone &&
        noteCommunicationResults.findIndex(res => res.value === values.communication_result_id) !==
          1 &&
        noteCommunicationResults.find(res => res.value === values.communication_result_id)
          ? noteCommunicationResults.find(res => res.value === values.communication_result_id)
              .requires_action
          : 0,
      patient_contact_id: values.patient_contact_id,
      communication_dt: convertToArborDate(values.communication_dt).getUtcDatetime(),
      communication_result_id: values.communication_result_id,
    };
    const contact = newValues.patient_contact;
    if (contact === 'patient') {
      communication.is_patient = 1;
    } else {
      // eslint-disable-next-line
      if (Number(contact)) {
        communication.patient_contact_id = contact;
        communication.other_contact_type_id = null;
      } else {
        communication.patient_contact_id = null;
        communication.other_contact_type_id = otherCommunicationOptions.find(
          cont => contact === cont.value,
        ).lookupValue;
      }
    }

    const payload = {
      patient_id: patient.id,
      note_type_id: 2,
      note_text: newValues.note_text,
      is_pinned: 0,
      followup_dt:
        values.followup_dt &&
        convertToArborDate(values.followup_dt).getUtcDatetime() !==
          convertToArborDate(task.followup_dt).getUtcDatetime()
          ? convertToArborDate(values.followup_dt).getUtcDatetime()
          : null,
      mentions: newValues.mentions,
      tags,
      communication,
    };

    const notesPayload = {
      note: payload,
      formId: embeddedFormId,
      followup_dt: values.followup_dt,
    };

    addNoteCommunication(notesPayload);
    if (task && task.taskType) {
      updateTaskProperties(task.taskType, task.id, {
        followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      });
    }
    reset();
    cancelCallback();
  }

  handleCancel() {
    const { reset, cancelCallback } = this.props;
    reset();
    cancelCallback();
  }

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      pristine,
      users,
      formValues, // eslint-disable-line
      sendSMS, // eslint-disable-line
      contactList,
      communicationType,
      scheduleOutreach,
      tagTypeId,
      invalid,
      ongoingPhoneCall,
      tagResourceId,
    } = this.props;

    const userData = users && users.length > 0 && translateUsers(users);

    let contactOptions = [{ label: 'Patient', value: 'patient' }];
    if (contactList) {
      Object.keys(contactList).forEach(contactId => {
        const contact = contactList[contactId];
        if (contact.first_name) {
          contactOptions.push({
            label: `${contact.first_name} ${handleNullString(contact.last_name)} ${handleNullString(
              contact.suffix,
            )} (${contact.relationship})`,
            value: Number(contactId),
          });
        }
      });
    }
    contactOptions = contactOptions.concat(otherCommunicationOptions);
    const radioProps = {
      width: '100%',
      radius: 5,
      checkedColor: deepBlue2,
    };

    const isSms = () => communicationType === CommunicationType.Sms;

    const noteCommunicationTypesIcons = [
      { label: 'Phone', value: NOTE_TYPES.phone_call, icon: <Call />, scheduleOutreach: true },
      { label: 'In Person', value: NOTE_TYPES.in_person, icon: <Face />, scheduleOutreach: true },
      { label: 'Email', value: NOTE_TYPES.email, icon: <Email /> },
      { label: 'SMS', value: NOTE_TYPES.sms, icon: <Sms /> },
      { label: 'Letter', value: NOTE_TYPES.mail, icon: <MailOutline /> },
      { label: 'Fax', value: NOTE_TYPES.fax, icon: <Print /> },
      { label: 'EHR', value: NOTE_TYPES.ehr, icon: <Computer /> },
    ];

    const noteTagData = {
      tagTypeId,
      tagResourceId,
    };

    return (
      <div
        className={
          ongoingPhoneCall
            ? classes.communicationFormContainerMinimal
            : classes.communicationFormContainer
        }
      >
        <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
          <Grid container spacing={3}>
            {ongoingPhoneCall && (
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.followupDate}>
                  Phone - Outgoing
                </Typography>
              </Grid>
            )}
            {!ongoingPhoneCall && !scheduleOutreach && !isSms() && (
              <Grid item xs={12}>
                <Field
                  name="is_incoming"
                  className={classes.radioGroup}
                  id="is_incoming"
                  radioMap={communicationIngoingOutgoing}
                  component={renderReactRadioGroup}
                  buttonProps={{ ...radioProps }}
                  validate={[required]}
                  width="100%"
                  qaId="add_is_incoming"
                />
              </Grid>
            )}
            {!ongoingPhoneCall && (
              <>
                {scheduleOutreach ? (
                  <Grid item xs={12}>
                    <Field
                      name="communication_type_id"
                      className={classes.radioGroup}
                      id="communication_type_id"
                      radioMap={noteCommunicationTypesIcons.filter(
                        n => n.scheduleOutreach === true,
                      )}
                      component={renderReactRadioGroup}
                      buttonProps={{ ...radioProps }}
                      validate={[required]}
                      width="100%"
                      qaId="add_communication_type_id_schedule_outreach"
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Field
                      name="communication_type_id"
                      className={classes.radioGroup}
                      id="communication_type_id"
                      radioMap={noteCommunicationTypesIcons}
                      component={renderReactRadioGroup}
                      buttonProps={{ ...radioProps }}
                      validate={[required]}
                      width="100%"
                      qaId="add_communication_type_id"
                    />
                  </Grid>
                )}
              </>
            )}
            {!isSms() && (
              <Grid item xs>
                <Grid item xs>
                  <Field
                    name="communication_dt"
                    label="Date"
                    width="100%"
                    className={classes.full}
                    component={renderDateTimePicker}
                    validate={[required, validateDateTime]}
                  />
                </Grid>
                {!scheduleOutreach && (
                  <Grid item xs={6}>
                    <Field
                      name="patient_contact"
                      label="Contact"
                      width="100%"
                      component={renderDropdown}
                      fields={contactOptions}
                    />
                  </Grid>
                )}

                {/* eslint-disable-next-line */}
                {/* if communicationType is null we still need to render this field so that popover can calculate the correct height */}
                {(!communicationType || communicationType === CommunicationType.Phone) && (
                  <>
                    {!ongoingPhoneCall && (
                      <Grid item xs={6}>
                        <PhonePopup
                          onClick={this.handleCancel}
                          parentTagInfo={noteTagData}
                          disableFAB
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <Field
                        name="communication_result_id"
                        className={classes.radioGroup}
                        id="communication_result_id"
                        radioMap={noteCommunicationResults}
                        component={renderReactRadioGroup}
                        validate={[required]}
                        width="100%"
                        qaId="add_communication_result_id"
                      />
                    </Grid>
                  </>
                )}

                {tagTypeId >= 10 && ( // 10 is start of task tags
                  <Grid item xs={12}>
                    <Grid container className={classes.followupBlock}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.followupDate}>
                          {`Set ${
                            noteTagTypes.find(tag => tag.value === tagTypeId).label
                          } Task Follow Up Date`}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          className={classes.followupDate}
                          name="followup_dt"
                          component={renderDatePicker}
                          validate={[validateDate]}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CalendarToday className={classes.followupDateIcon} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} className={classes.followupBlock}>
                  <Field
                    name="note_text"
                    data={userData}
                    component={NoteArea}
                    validate={[required]}
                    defaultPlaceholder="Add a Note (Required)"
                  />
                </Grid>
              </Grid>
            )}

            {!isSms() && (
              <Grid item xs={12}>
                <ConfirmationPanel
                  handleCancel={this.handleCancel}
                  disableSubmit={pristine || submitting || invalid}
                />
              </Grid>
            )}
          </Grid>
        </form>
        {isSms() && (
          <Grid container className={classes.smsConversationWrapper} spacing={2}>
            <Grid item xs={12}>
              <Conversation />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <SendForm handleCancel={this.handleCancel} />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { patient, filters, contactList, view } = state;
  const propsNeededForPatient = { id: patient.id, phones: patient.phones };
  const { startOnSms } = props;
  const { ongoingPhoneCall, phoneNoteTags } = view;
  const task = state.noteBar?.initiatingTask || props.task;
  const formName = ongoingPhoneCall ? COMMUNICATION_FORM_PHONE_CALL : COMMUNICATION_FORM;

  const selector = formValueSelector(formName);
  const communicationType = selector(state, 'communication_type_id')
    ? Number(selector(state, 'communication_type_id'))
    : null;

  const availablePatientPhones = (patient.phones || []).filter(phone => phone.use === 'Mobile');

  const preferredContactItem =
    availablePatientPhones.length > 0 &&
    availablePatientPhones.indexOf(availablePatientPhones.find(phone => phone.sms === 'Opt in'));

  const initialValues = {
    communication_dt: convertToArborDate(getTodayDateTime(), false).getCustomerDate(
      true,
      'MM/DD/YYYY hh:mm A',
    ),
    is_incoming: '0',
    preferred_contact_item: preferredContactItem,
    communication_type_id: startOnSms === true ? CommunicationType.Sms : CommunicationType.Phone,
    patient_contact: patient.preferred_contact_id ?? PATIENT,
    communication_result_id: 2,
    tabValue: 1,
    followup_dt:
      task && task.followup_dt ? convertToArborDate(task.followup_dt).getCustomerDate(true) : null,
  };

  const formValues = getFormValues(formName)(state) || initialValues;

  return {
    patient: propsNeededForPatient,
    filters,
    contactList,
    communicationType,
    availablePatientPhones,
    form: formName,
    initialValues,
    formValues,
    users: state.lookups.users,
    task,
    ongoingPhoneCall,
    phoneNoteTags,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addPatient,
    addNoteCommunication,
    sendSMS,
    validatePHI,
    change,
    fetchPatient,
    updateTaskProperties,
    selectPatientTab,
  }),
)(reduxForm({})(CommunicationForm));
