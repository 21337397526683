import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import DcQuestionnaire from 'containers/tasks/data-collect/dc-questionnaire';
import {
  getClinicalDataTypesMap,
  getTreeMapFromDataTypes,
  getFirstCategoryOfDataType,
  getFieldProperties,
  getFieldsIdsWithConditional,
} from 'services/utils/data-collect';
import { fetchGpis } from 'services/utils/therapy-service';
import { getUserById } from 'services/utils/users-service';
import { Grid, Box, Modal, Typography, Tooltip, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getModalStyle } from 'services/utils/styles-service';
import { touch, change } from 'redux-form';
import { ToggleDisplayAddTherapyModal } from 'actions/action-form-displays';
import useHasRole from 'hooks/useHasRole';
import AddPatientTherapy from 'containers/patient/therapy/add-patient-therapy/add-patient-therapy';
import useStore from 'hooks/useStore';
import { fetchDcProtocols } from 'actions/action-dc-protocols';
import { getStatusByStatusId } from 'services/utils/task-service';
import {
  DATA_COLLECT_STATUS_DATA_COLLECTED,
  DATA_COLLECT_STATUS_DATA_REVIEWED,
  DATA_COLLECT_STATUS_CANCELED,
  TASK_CONTEXT,
  MED_SYNC_STATUS,
  DC,
  RS,
  PROMISS_ID_QUESTIONNAIRE,
} from 'constants/index';
import { UserRoles, TherapyTypes, MedicationStatus, FailedTherapies } from 'constants/enums';
import { DownloadFileUtils } from 'utils/download-file-utils';
import { GeneratorUtil } from 'components/document/generator/generator-util';
import { convertToArborDate } from 'models/time/arbor-date';
import { notifyError } from 'actions/action-notifications';
import { cloneDeep } from 'lodash';

import { windowFeatureIsEnabled } from 'config/window-features';
import moment from 'moment';
import { QuestionnairePrintPayload } from '../../document/generator/questionnaire-print-payload';
import DynamicForm from '../dynamic-form';
import DynamicField from '../dynamic-field';
import DataCollectOnDemandForm from './data-collect-on-demand-form';
import { getGeneratedDocument } from '../../../services/utils/document-service';
import { logger } from '../../../winston-logger';
import { saveClinicalData, fetchClinicalData } from '../../../actions/action-clinical-data';
import { ClinicalDataClient } from '../../../clients/clinical-data';
import { CdmProgramClient } from '../../../clients/cdm-program';
import { getUniqueListOfGpi10 } from '../../../services/utils/medication-service';
import { shouldFetchDcProtocols } from '../../../containers/patient/tasks-new/util';

const useStyles = makeStyles(theme => {
  const tableHeaderBase = {
    border: `1px solid ${theme.palette.primary.grey12}`,
    marginRight: -1,
    wordBreak: 'break-all',
    fontWeight: 450,
    textAlign: 'center',
  };

  const ellipsisBase = {
    maxWidth: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(1.3),
    paddingLeft: theme.spacing(1.3),
  };

  return {
    addTherapyModal: {
      position: 'absolute',
      width: theme.spacing(120),
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
    container: {
      position: 'relative',
    },
    onDemandButton: {
      position: 'absolute',
      right: 10,
      top: 44,
    },
    regularField: {
      paddingTop: 20,
    },
    conditionalField: {
      backgroundColor: theme.palette.primary.trellisLightBlue,
      paddingTop: 20,
    },
    recommendationsContainer: {
      border: `1px solid ${theme.palette.primary.grey12}`,
      padding: theme.spacing(2),
      margin: theme.spacing(1),
    },
    recommendationsTitle: {
      fontWeight: 'bold',
    },
    recommendationsList: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    tableHeaderCell: {
      ...tableHeaderBase,
      padding: theme.spacing(1),
    },
    tableHeaderLastCell: {
      '& p': {
        ...tableHeaderBase,
        ...ellipsisBase,
        padding: theme.spacing(0.7),
      },
    },
    riskStratificationBadge: {
      borderRadius: 5,
      color: theme.palette.primary.white,
      padding: theme.spacing(0, 0.5),
      marginLeft: 5,
      height: theme.spacing(2.75),
      backgroundColor: theme.palette.primary.orange,
    },
    ellipsis: {
      ...ellipsisBase,
      marginTop: theme.spacing(1),
    },
    therapyTooltipText: {
      fontSize: '11px',
    },
    applyDateToAllDCRow: {
      backgroundColor: theme.palette.primary.trellisLightBlue,
      padding: theme.spacing(1),
    },
  };
});

export default function (props) {
  const {
    clinicalDataItems,
    initialClinicalDataItems,
    workingAsGroupClinicalDataItems,
    onChange,
    onValidityChange,
    isAdd,
    fullAccess,
    meta = {},
    required,
    dataCollectInformation,
    formId: parentFormId,
    dcFormId,
    therapy,
    onFailedTherapySubmitted,
    patient,
    workingAsGroup,
    isRiskStrat,
    providerTaskId,
    workingAsGroupTasks,
    dcList,
    clinicalDataItemsList,
  } = props;

  const cleanDcList = (dcList, clinicalDataItemsList) => {
    const finalDCItemsList = [];
    if (dcList && clinicalDataItemsList && dcList.length && clinicalDataItemsList.length) {
      const tempDcList = cloneDeep(dcList);
      const tempClinicalDataItemsList = cloneDeep(clinicalDataItemsList);
      for (const dcElement of tempDcList) {
        if (
          finalDCItemsList.find(t => t.clinicalDataTypeId === dcElement.clinicalDataTypeId) ===
          undefined
        ) {
          const sortedClinicalDataItemsList = tempClinicalDataItemsList
            .reduce((acc, it) => {
              if (it.clinicalDataTypeId === parseInt(dcElement.clinicalDataTypeId, 10)) {
                acc.push(it);
              }
              return acc;
            }, [])
            .sort((a, b) => b.id - a.id);
          if (sortedClinicalDataItemsList.length) {
            const lastItemVersion = sortedClinicalDataItemsList[0];
            const fixedItem = {
              ...dcElement,
              id: lastItemVersion.id,
              value: lastItemVersion.value,
            };
            finalDCItemsList.push(fixedItem);
          } else {
            finalDCItemsList.push(dcElement);
          }
        }
      }
    }
    return finalDCItemsList;
  };

  const cleanClinicalDataItemsList = listOfClinicalItems => {
    const finalSortedClinicalDataItems = [];
    if (listOfClinicalItems && listOfClinicalItems.length) {
      const tempClinicalDataItemsList = cloneDeep(listOfClinicalItems);
      tempClinicalDataItemsList.map((it, index, arr) => {
        if (
          finalSortedClinicalDataItems.find(
            item => item.clinicalDataTypeId === it.clinicalDataTypeId,
          ) === undefined
        ) {
          const itemToStore = arr
            .reduce((acc, t) => {
              if (t.clinicalDataTypeId === it.clinicalDataTypeId) {
                acc.push(t);
              }
              return acc;
            }, [])
            .sort((a, b) => b.id - a.id)[0];
          if (Object.keys(itemToStore).length) {
            finalSortedClinicalDataItems.push(itemToStore);
          }
        }
      });
    }
    return finalSortedClinicalDataItems;
  };

  const getClinicalItems = (listOfClinicalItems, dcList, clinicalDataItemsList, isRiskStrat) => {
    if (isRiskStrat) {
      return listOfClinicalItems;
    }
    if (listOfClinicalItems && listOfClinicalItems.length) {
      if (typeof listOfClinicalItems[0].clinicalDataTypeId === 'string') {
        return cleanDcList(dcList, clinicalDataItemsList);
      }
      if (typeof listOfClinicalItems[0].clinicalDataTypeId === 'number') {
        return cleanClinicalDataItemsList(listOfClinicalItems);
      }
    }
    return listOfClinicalItems;
  };

  const formId = `${parentFormId}-data_collect`;
  const classes = useStyles();
  const formDisplays = useSelector(state => state.formDisplays);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [failedTherapy, setFailedTherapy] = useState(null);
  const [isCDMTherapy, setIsCDMTherapy] = React.useState(false);
  const [disableSetInitialValuesWorkingAsGroup, setDisableSetInitialValuesWorkingAsGroup] =
    useState(false);
  const [dateChangeAll, setDateChangeAll] = useState(null);
  const [shouldCreateNewQuestionnaire, setShouldCreateNewQuestionnaire] = useState(null);
  const [newQuestionnaireWasCreated, setNewQuestionnaireWasCreated] = useState(null);
  const [missingItemsToCreateState, setMissingItemsToCreateState] = useState(null);
  const users = useSelector(state => state.lookups.users);
  const forms = useSelector(state => state.form);
  const questionnaires = useSelector(state => state.lookups.patientQuestionnaires);
  const medications = useSelector(state => state.medications.medicationGroups);
  const [riskStratificationDataTypes, setRiskStratificationDataTypes] = useState(null);
  const dcId = dataCollectInformation ? dataCollectInformation.id : null;
  const tabContext = dataCollectInformation?.context;
  const wagTasksKeys = useSelector(state => state.tasks.selectedWagTaskKeys);
  const [
    recommendations,
    clinicalDataTypes,
    clinicalData,
    therapies,
    tasks,
    selectedTaskKeys,
    mergeInterventionMapping,
  ] = useStore(
    `dcProtocols.data.${dcId}.recommendationTherapies`,
    'lookups.clinicalDataTypes',
    'clinicalData',
    'therapies.data',
    'tasks.data',
    'tasks.selectedTaskKeys',
    'tasks.mergeInterventionMapping',
  );

  const workingAsGroupTasksIds =
    workingAsGroupTasks && workingAsGroupTasks.length > 0
      ? workingAsGroupTasks.map(t => t.task_id)
      : [];
  const workingClinicalDataItems =
    getClinicalItems(workingAsGroupClinicalDataItems, dcList, clinicalDataItemsList, isRiskStrat) ||
    getClinicalItems(clinicalDataItems, dcList, clinicalDataItemsList, isRiskStrat);
  const taskSpecificClinicalDataItems =
    workingAsGroupTasksIds && workingAsGroupTasksIds.length > 0
      ? workingClinicalDataItems.filter(item =>
          workingAsGroupTasksIds.some(taskId => taskId === item.taskDataCollectId),
        )
      : workingClinicalDataItems;

  const clinicalDataTypesMap = useMemo(
    () => getClinicalDataTypesMap(clinicalDataTypes),
    [clinicalDataTypes],
  );
  const dcItems =
    taskSpecificClinicalDataItems?.map(item => {
      const dataType =
        item.clinicalDataTypeId && clinicalDataTypesMap[item.clinicalDataTypeId].type
          ? clinicalDataTypesMap[item.clinicalDataTypeId].type
          : '';
      return {
        ...item,
        value:
          item.value && typeof item.value === 'string' && ['list', 'multiselect'].includes(dataType)
            ? item.value.toLowerCase()
            : item.value,
      };
    }) || [];
  const selectedFieldIds = useMemo(
    () =>
      dcItems.map(
        it =>
          `${getFirstCategoryOfDataType(clinicalDataTypes, it.clinicalDataTypeId).categoryId}-${
            it.clinicalDataTypeId
          }`,
      ),
    [dcItems, clinicalDataTypes],
  );
  const isWorkingAsGroup = workingAsGroup?.length > 0;

  const [fieldIds, setFieldIds] = useState(selectedFieldIds);

  const dispatch = useDispatch();

  useEffect(() => {
    const selectedFieldIdsNoDuplicates = [...new Set(selectedFieldIds)];
    setFieldIds(selectedFieldIdsNoDuplicates);
    if (isWorkingAsGroup && workingAsGroupTasksIds) {
      dispatch(
        change(formId, 'data_collect', {
          valid:
            forms && forms[formId] && forms[formId].values && forms[formId].values.data_collect
              ? forms[formId].values.data_collect
              : false,
          values: taskSpecificClinicalDataItems,
        }),
      );
      dispatch(change(formId, 'additional-field', selectedFieldIdsNoDuplicates));
    } else {
      setDisableSetInitialValuesWorkingAsGroup(false);
    }
  }, [workingAsGroup]);

  const medicationList = getUniqueListOfGpi10(medications);

  const isRiskStratification =
    therapy &&
    therapy.therapy_type !== TherapyTypes.NonSpecialty &&
    dcItems.some(item => {
      const itemDataType = Number(item.clinicalDataTypeId);
      return riskStratificationDataTypes?.includes(itemDataType);
    });

  const allFields = useMemo(
    () => Object.keys(clinicalDataTypesMap).map(dataTypeId => clinicalDataTypesMap[dataTypeId]),
    [clinicalDataTypesMap],
  );
  const fieldsIdsWithConditional = useMemo(
    () => getFieldsIdsWithConditional(fieldIds, allFields, taskSpecificClinicalDataItems),
    [fieldIds, allFields, clinicalDataItems],
  );

  const fieldsWithConditional = useMemo(
    () =>
      fieldsIdsWithConditional.map(
        it => `${getFirstCategoryOfDataType(clinicalDataTypes, it.id).categoryId}-${it.id}`,
      ),
    [fieldsIdsWithConditional, clinicalDataTypes, workingAsGroup],
  );

  const isNullOrUndefined = obj => obj === undefined || obj === null;

  const validators = useMemo(
    () =>
      fieldsIdsWithConditional.reduce((acc, fieldWithConditional) => {
        const { id } = fieldWithConditional;

        acc[id] = {
          assessDateValidator: required
            ? [
                (value, values) => {
                  // Assessment date must be defined if this is for a RS task
                  // or if the corresponding lab value is present.
                  const labValue = values[`${id}-value`];
                  const labValuePresent = !isNullOrUndefined(labValue) && labValue !== '';
                  return !value && (isRiskStrat || labValuePresent) ? 'Required' : '';
                },
              ]
            : null,
          labValueValidator: required
            ? [
                (value, values) => {
                  // The lab value must be defined if this is for a RS task
                  // or if the value is marked as required
                  if (isRiskStrat || values[`${id}-required`]) {
                    const labValuePresent = !isNullOrUndefined(value) && value !== '';
                    const cannotCompleteValue = values[`${id}-cannot-complete-reason`];
                    const cannotCompletePresent =
                      !isNullOrUndefined(cannotCompleteValue) && cannotCompleteValue !== '';
                    return labValuePresent || cannotCompletePresent ? '' : 'Required';
                  }
                  return false;
                },
              ]
            : null,
        };
        return acc;
      }, {}),
    [fieldsIdsWithConditional, required, isRiskStrat],
  );

  const statuses = useSelector(state => state.taskStatuses.statuses.dc);

  const taskStatus = dataCollectInformation
    ? getStatusByStatusId(dataCollectInformation.status_id, statuses)
    : '';

  // eslint-disable-next-line no-unused-vars
  const showDcOnDemandForm =
    taskStatus !== DATA_COLLECT_STATUS_DATA_COLLECTED &&
    taskStatus !== DATA_COLLECT_STATUS_DATA_REVIEWED &&
    taskStatus !== DATA_COLLECT_STATUS_CANCELED;

  const canUncheckRequired =
    fullAccess ||
    useHasRole(
      UserRoles.SuperUser,
      UserRoles.GlobalAdmin,
      UserRoles.SiteManager,
      UserRoles.CredentialedPharmacist,
      UserRoles.NonCredentialedPharmacist,
    );

  const providers = useMemo(() => {
    const result = {
      fields: getTreeMapFromDataTypes(clinicalDataTypes),
      dataCollectId: dataCollectInformation ? dataCollectInformation.id : null,
      patientClinicalDataId: dataCollectInformation
        ? dataCollectInformation.patientClinicalDataId
        : null,
    };
    allFields.forEach(field => {
      if (field) {
        if (['list', 'multiselect'].includes(field.type)) {
          const options = field.option ? field.option.split(';').map(option => option.trim()) : [];
          result[`list-${field.id}`] = options.map(k => ({
            id: k?.toLowerCase(),
            name: k,
          }));
        }
        if (field.references && field.references.length > 0) {
          result[`references-${field.id}`] = field.references;
        }
        if (field.questionnaireId) {
          result[`data-type-field-${field.id}`] = field;
        }
        if (field.type === 'therapy') {
          result[`data-type-field-${field.id}`] = medicationList;
        }
      }
    });
    return result;
  }, [clinicalDataTypes, dataCollectInformation, allFields, medicationList]);

  useEffect(() => {
    if (meta.submitFailed && !isAdd) {
      dispatch(
        touch(
          formId,
          ...[
            ...allFields.map(f => `${String(f.dataTypeId)}-value`),
            ...allFields.map(f => `${String(f.dataTypeId)}-date`),
          ],
        ),
      );
    }
  }, [meta.submitFailed, allFields]);

  const data = useMemo(
    () =>
      dcItems.reduce(
        (acc, it) => {
          const arborAssessmentDate = convertToArborDate(it.assessmentDate);
          acc[`${it.clinicalDataTypeId}-id`] = it.id;
          acc[`${it.clinicalDataTypeId}-date`] = arborAssessmentDate.getUtcDate(true);
          acc[`${it.clinicalDataTypeId}-value`] = it.value;
          acc[`${it.clinicalDataTypeId}-cannot-complete-reason`] = it.cannotCompleteReason;
          acc[`${it.clinicalDataTypeId}-required`] = it.required;
          acc[`${it.clinicalDataTypeId}-id`] = it.id;
          return acc;
        },
        {
          'additional-field': fieldIds,
          ...(isAdd
            ? Object.keys(allFields).reduce((acc, f) => {
                // When creating DC, default to true always
                acc[`${String(allFields[f].dataTypeId)}-required`] = true;
                return acc;
              }, {})
            : Object.keys(allFields).reduce((acc, f) => {
                // When editing DC, default to true when type is questionnaire
                acc[`${String(allFields[f].dataTypeId)}-required`] =
                  allFields[f].type === 'questionnaire';
                return acc;
              }, {})),
        },
      ),
    [fieldIds, dcItems, isAdd, allFields],
  );

  const [previousValues, setPreviousValues] = useState([]);
  const checkPreviousValues = useCallback(
    values => {
      const newValues = Object.keys(values).reduce(
        (p, c) => (/(-value)/.test(c) ? [...p, values[c]] : p),
        [],
      );

      return (
        (previousValues.length !== newValues.length ||
          previousValues.some((v, i) => v !== newValues[i])) &&
        (setPreviousValues(newValues) || true)
      );
    },
    [previousValues],
  );

  const handleOnChange = useCallback(
    (values, valid) => {
      // handle recommendations
      let patientClinicalData = [];
      const workAsGroupDCValues = {};

      if (
        values &&
        values.data_collect &&
        values.data_collect.values &&
        !disableSetInitialValuesWorkingAsGroup
      ) {
        values.data_collect.values.forEach(dcVar => {
          workAsGroupDCValues[`${dcVar.clinicalDataTypeId}-cannot-complete-reason`] =
            dcVar.cannotCompleteReason;
          workAsGroupDCValues[`${dcVar.clinicalDataTypeId}-date`] = dcVar.assessmentDate;
          workAsGroupDCValues[`${dcVar.clinicalDataTypeId}-id`] = dcVar.id;
          workAsGroupDCValues[`${dcVar.clinicalDataTypeId}-required`] = dcVar.required;
          workAsGroupDCValues[`${dcVar.clinicalDataTypeId}-value`] = dcVar.value;
        });
      }
      const valuesToUse =
        isWorkingAsGroup && !disableSetInitialValuesWorkingAsGroup
          ? { ...values, ...workAsGroupDCValues }
          : values;
      if (fieldsWithConditional) {
        patientClinicalData = fieldsWithConditional.reduce((acc, fieldId) => {
          const id = fieldId.split('-')[1];
          const storedDataTypes = acc.map(t => t.dataType);
          if (!storedDataTypes.includes(Number(id))) {
            acc.push({
              id: valuesToUse[`${id}-id`],
              dataType: Number(id),
              value: valuesToUse[`${id}-value`],
              date: valuesToUse[`${id}-date`],
            });
          }
          return acc;
        }, []);
      }
      if (dataCollectInformation && checkPreviousValues(values) && !isWorkingAsGroup) {
        const shouldFetchProtocols = shouldFetchDcProtocols(
          dataCollectInformation.id,
          mergeInterventionMapping ?? [],
          selectedTaskKeys,
        );
        if (
          windowFeatureIsEnabled('merge_interventions') &&
          shouldFetchProtocols.fetchDcProtocols &&
          shouldFetchProtocols.tasksData.length
        ) {
          for (const tData of shouldFetchProtocols.tasksData) {
            if (tData.taskId === dataCollectInformation.id) {
              dispatch(fetchDcProtocols(dataCollectInformation.id, therapy, patientClinicalData));
            } else {
              const tempClinicalData = clinicalData
                .filter(cd => cd.taskDataCollectId === tData.taskId)
                .map(b => {
                  return {
                    id: b.id,
                    dataType: b.clinicalDataTypeId,
                    value: b.value,
                    date: moment(b.assessmentDate).utc().format('MM/DD/yyyy'),
                  };
                });
              dispatch(
                fetchDcProtocols(tData.taskId, therapies[tData.therapyId], tempClinicalData),
              );
            }
          }
        } else {
          dispatch(
            fetchDcProtocols(
              dataCollectInformation.id,
              therapy,
              patientClinicalData,
              isRiskStrat ? tasks[`${RS}${dataCollectInformation.id}`].rsType : null,
            ),
          );
        }
      } else if (dataCollectInformation && checkPreviousValues(values) && isWorkingAsGroup) {
        const tempTasksObjs = Object.values(tasks);
        const wagTasks = tempTasksObjs.reduce((acc, it) => {
          if (wagTasksKeys.includes(`${it.taskType}${it.id}`)) {
            acc.push(it);
          }
          return acc;
        }, []);
        if (wagTasks && wagTasks.length) {
          for (const wagT of wagTasks) {
            const wagPatientClinicalData = clinicalData
              .filter(cd => cd.taskDataCollectId === wagT.id)
              .map(b => {
                const latestValues = patientClinicalData.find(
                  c => c.dataType === b.clinicalDataTypeId,
                );
                return {
                  id: b.id,
                  dataType: b.clinicalDataTypeId,
                  value: latestValues?.value,
                  date: latestValues?.date,
                };
              });
            dispatch(fetchDcProtocols(wagT.id, therapies[wagT.therapy_id], wagPatientClinicalData));
          }
        }
      }
      if (isWorkingAsGroup && !disableSetInitialValuesWorkingAsGroup) {
        const fieldsToUpdate = Object.keys(workAsGroupDCValues);
        fieldsToUpdate.forEach(fieldVar => {
          if (forms[formId]?.values[fieldVar] !== workAsGroupDCValues[fieldVar]) {
            dispatch(change(`${formId}`, fieldVar, workAsGroupDCValues[fieldVar]));
          }
        });
        setDisableSetInitialValuesWorkingAsGroup(true);
      }
      onChange(valuesToUse, valid);
    },
    [
      onChange,
      dispatch,
      dataCollectInformation,
      fieldsWithConditional,
      workingAsGroup?.length,
      clinicalDataItems,
    ],
  );

  // Therapy Modal
  const onToggleFormDisplay = bool => {
    dispatch(new ToggleDisplayAddTherapyModal(bool));
  };

  const handleToggleAddMedication = () => {
    onToggleFormDisplay(true);
  };

  const handleDateChangeAll = () => {
    if (!fieldsIdsWithConditional || fieldsIdsWithConditional.length === 0) {
      return;
    }
    const idsOfClinicalDataItems = fieldsIdsWithConditional.map(f => f.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const id of idsOfClinicalDataItems) {
      dispatch(change(formId, `${id}-date`, dateChangeAll));
    }
  };

  const updateFormId = (targetId, newValue) => {
    if (!fieldsIdsWithConditional || fieldsIdsWithConditional.length === 0) {
      return;
    }
    const idsOfClinicalDataItems = fieldsIdsWithConditional.map(f => f.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const id of idsOfClinicalDataItems) {
      if (parseInt(id, 10) === targetId) {
        dispatch(change(formId, `${id}-id`, newValue));
      }
    }
  };

  const printButtonFunctionality = field => {
    const dateTypeId = field.dataTypeId;
    // Print button is only active for submitted Rapid 3
    if (dateTypeId === QuestionnairePrintPayload.RAPID_3_DATA_TYPE_ID) {
      const dcData = dcItems.find(it => Number(it.clinicalDataTypeId) === Number(dateTypeId));
      const dcDataItem = clinicalData.find(item => item.id === dcData?.id);
      const questionnaireData = dcDataItem?.questionnaire;
      const questionnaireSubmittedDate = questionnaireData?.submittedDate;
      if (questionnaireSubmittedDate) {
        const { submittedBy, answers, score } = questionnaireData;
        const { questions } = questionnaires[field.questionnaireId];
        return async () => {
          const submittedByUser = getUserById(submittedBy, users);
          const pdc = new QuestionnairePrintPayload();
          const payload = await pdc.getPayload(
            patient,
            field.name,
            questions,
            answers,
            score,
            field.references,
            submittedByUser,
            questionnaireSubmittedDate,
          );
          const generatedDocumentResponse = await getGeneratedDocument(
            payload,
            patient.customer_id,
            patient.id,
          );
          const viewableResult = GeneratorUtil.convertToViewable(
            generatedDocumentResponse.data,
            field.name,
          );
          DownloadFileUtils.downloadUrl(viewableResult.url, viewableResult.fileName);
        };
      }
    }
    return null;
  };

  const createClinicalDataItem = async clinicalDataTypeId => {
    const newItems = clinicalDataTypeId.map(item => ({
      assessmentDate: null,
      cannotCompleteReason: null,
      clinicalDataTypeId: item.dataTypeId,
      required: false,
      taskDataCollectId: item.dcTaskId,
      therapyId: item.therapyId,
      value: null,
    }));
    try {
      const response = await ClinicalDataClient.save(patient.id, newItems);
      const responseData = response.data;
      const action = saveClinicalData(response.data);
      dispatch(action);
      return responseData;
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Failed to save clinical data'));
    }
    return null;
  };

  const lastRowForClinicalDataTypeId = clinicalDataTypeIdToSearch => {
    if (workingAsGroup && workingAsGroup.length) {
      const rowsWithAssessmentDate = taskSpecificClinicalDataItems
        .reduce((acc, item) => {
          if (isRiskStrat) {
            if (
              parseInt(item.clinicalDataTypeId, 10) === clinicalDataTypeIdToSearch &&
              item.taskRiskStratId === providerTaskId &&
              item.assessmentDate
            ) {
              acc.push(item);
            }
          } else if (
            parseInt(item.clinicalDataTypeId, 10) === clinicalDataTypeIdToSearch &&
            item.taskDataCollectId === providerTaskId &&
            item.assessmentDate
          ) {
            acc.push(item);
          }
          return acc;
        }, [])
        .sort((a, b) => b.assessmentDate.localeCompare(a.assessmentDate) || b.id - a.id);

      const rowsWithoutAssessmentDate = taskSpecificClinicalDataItems
        .reduce((acc, item) => {
          if (isRiskStrat) {
            if (
              parseInt(item.clinicalDataTypeId, 10) === clinicalDataTypeIdToSearch &&
              item.taskRiskStratId === providerTaskId &&
              item.assessmentDate === null
            ) {
              acc.push(item);
            }
          } else if (
            parseInt(item.clinicalDataTypeId, 10) === clinicalDataTypeIdToSearch &&
            item.taskDataCollectId === providerTaskId &&
            item.assessmentDate === null
          ) {
            acc.push(item);
          }
          return acc;
        }, [])
        .sort((a, b) => b.id - a.id);
      return rowsWithAssessmentDate.concat(rowsWithoutAssessmentDate)[0];
    }
    const rowsWithAssessmentDate = taskSpecificClinicalDataItems
      .reduce((acc, item) => {
        if (
          parseInt(item.clinicalDataTypeId, 10) === clinicalDataTypeIdToSearch &&
          item.assessmentDate
        ) {
          acc.push(item);
        }
        return acc;
      }, [])
      .sort((a, b) => b.assessmentDate.localeCompare(a.assessmentDate) || b.id - a.id);

    const rowsWithoutAssessmentDate = taskSpecificClinicalDataItems
      .reduce((acc, item) => {
        if (
          parseInt(item.clinicalDataTypeId, 10) === clinicalDataTypeIdToSearch &&
          item.assessmentDate === null
        ) {
          acc.push(item);
        }
        return acc;
      }, [])
      .sort((a, b) => b.id - a.id);
    return rowsWithAssessmentDate.concat(rowsWithoutAssessmentDate)[0];
  };

  const buttonFunctionality = (field, taskId, isRs, idForClinicalDataType) => {
    switch (field.type) {
      case 'questionnaire':
        return async () => {
          const patientId = patient?.id;
          const response = await ClinicalDataClient.fetch(patientId);
          const fetchAction = fetchClinicalData(response.data);
          dispatch(fetchAction);
          const fieldDataTypeId = Number(field.dataTypeId);
          const dcData = clinicalData.filter(
            it =>
              Number(it.clinicalDataTypeId) === fieldDataTypeId &&
              (workingAsGroup ? workingAsGroup.includes(it.therapyId) : true),
          );

          const workAsGroupDcTasks = Object.values(tasks).filter(
            t =>
              t.taskType === DC && (workingAsGroup ? workingAsGroup.includes(t.therapy_id) : true),
          );
          const dcItemsToCreate = dcData.reduce((acc, it) => {
            if (
              isRs !== true &&
              acc.find(
                t =>
                  it.therapyId === t.therapyId &&
                  fieldDataTypeId === t.dataTypeId &&
                  it.taskDataCollectId === t.dcTaskId,
              ) === undefined &&
              it.taskDataCollectId === taskId
            ) {
              acc.push({
                therapyId: it.therapyId,
                dataTypeId: fieldDataTypeId,
                dcTaskId: it.taskDataCollectId,
              });
            } else if (
              isRs &&
              it.taskRiskStratId === taskId &&
              acc.find(
                t =>
                  it.therapyId === t.therapyId &&
                  fieldDataTypeId === t.dataTypeId &&
                  it.taskRiskStratId === t.dcTaskId,
              ) === undefined
            ) {
              acc.push({
                therapyId: it.therapyId,
                dataTypeId: fieldDataTypeId,
                rsTaskId: it.taskRiskStratId,
              });
            }
            return acc;
          }, []);

          const missingItemsToCreate =
            dcItemsToCreate.length > 0
              ? dcItemsToCreate
              : workingAsGroup === undefined
              ? workAsGroupDcTasks
                  .filter(t => t.id === taskId)
                  .map(it => ({
                    therapyId: it.therapy_id,
                    dataTypeId: fieldDataTypeId,
                    dcTaskId: it.id,
                  }))
              : workAsGroupDcTasks.map(it => ({
                  therapyId: it.therapy_id,
                  dataTypeId: fieldDataTypeId,
                  dcTaskId: it.id,
                }));
          const questionnaireItemId = isRs
            ? dcData.find(it => it.taskRiskStratId === taskId && it.id === idForClinicalDataType)
            : idForClinicalDataType
            ? dcData.find(it => it.taskDataCollectId === taskId && it.id === idForClinicalDataType)
            : dcData.find(it => it.taskDataCollectId === taskId);
          // If the item for the questionnaire does not exist, create it
          if (
            dcData.length > 0 &&
            questionnaireItemId &&
            Object.keys(questionnaireItemId).length &&
            questionnaireItemId.value &&
            questionnaireItemId.questionnaire.score
          ) {
            setShouldCreateNewQuestionnaire(true);
            setNewQuestionnaireWasCreated(null);
            setMissingItemsToCreateState(missingItemsToCreate);
          }
          const itemsId =
            dcData.length > 0 && questionnaireItemId && Object.keys(questionnaireItemId).length
              ? [questionnaireItemId.id]
              : await createClinicalDataItem(missingItemsToCreate);
          if (itemsId && itemsId.length > 0) {
            setQuestionnaire({
              dataTypeField: field,
              clinicalDataItemsId:
                typeof itemsId[0] === 'number' ? itemsId : itemsId.map(item => item.id),
              workingAsGroup:
                workingAsGroup === undefined || !workingAsGroup?.length
                  ? undefined
                  : typeof itemsId[0] === 'object' && itemsId[0] !== null
                  ? itemsId.map(item => item.questionnaire.id)
                  : undefined,
            });
          }
        };
      case 'therapy':
        return () => {
          const dcData = dcItems.find(it => it.clinicalDataTypeId === field.dataTypeId);
          handleToggleAddMedication();
          return setFailedTherapy({
            dataTypeField: { ...dcData, ...field },
            taskId: providers.dataCollectId,
            patientClinicalDataId: providers.patientClinicalDataId,
          });
        };
      default:
        return null;
    }
  };
  // when we get recommendations, update data collect form to include them
  useEffect(() => {
    dispatch(
      change(
        dcFormId,
        'recommendations',
        (recommendations || []).map(recommendation => recommendation.planId),
      ),
    );
  }, [recommendations, dcFormId, dispatch]);

  const fetchAndSetRiskStratificationDataTypes = async () => {
    const patientId = patient?.id;
    // eslint-disable-next-line camelcase
    const diagnosisCode = therapy?.diagnosis_code;
    if (!riskStratificationDataTypes && patientId && diagnosisCode) {
      try {
        const response = await CdmProgramClient.fetch(patientId, diagnosisCode);
        const cdmPrograms = response.data;
        const rsDataTypes = cdmPrograms
          .flatMap(program => program.conditions)
          .map(condition => condition.clinicalDataTypeId);
        setRiskStratificationDataTypes(rsDataTypes);
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Unable to fetch CDM program data.'));
      }
    }
  };

  useEffect(() => {
    fetchAndSetRiskStratificationDataTypes();
  }, [riskStratificationDataTypes, patient, therapy]);

  const getTherapiesTooltip = listOfTherapies => {
    return (
      <>
        {listOfTherapies.map((t, i) => (
          <Typography className={classes.therapyTooltipText}>
            {listOfTherapies.length === 1 || i === listOfTherapies.length - 1 ? `${t}` : `${t},`}
          </Typography>
        ))}
      </>
    );
  };

  // The xs for the value type field depends on isAdd and on if any risk stratification items
  const xsValueTypeHeaderEdit = isWorkingAsGroup ? 2 : 3;
  const xsValueTypeHeader = isAdd ? 7 : xsValueTypeHeaderEdit;
  const xsValueTypeDynamicField = isRiskStratification ? xsValueTypeHeader - 1 : xsValueTypeHeader;

  return (
    <div className={classes.container}>
      {showDcOnDemandForm && tabContext === TASK_CONTEXT && (
        <DataCollectOnDemandForm
          dataCollectId={providers.dataCollectId}
          dc={clinicalDataItems}
          className={classes.onDemandButton}
          isRiskStrat={isRiskStrat}
          workingAsGroupTasks={workingAsGroupTasks}
        />
      )}
      <DynamicForm
        formId={formId}
        data={data}
        providers={providers}
        onValidityChange={onValidityChange}
        onFormChange={handleOnChange}
      >
        {!isRiskStrat && (
          <DynamicField
            id="additional-field"
            property="additional-field"
            type="text"
            component="checkboxMultiSelect"
            provider="fields"
            label="Which fields need to be collected?"
            gridSize={6}
            onChange={setFieldIds}
          />
        )}
        <Grid container className={classes.tableHeader}>
          <Grid item xs={xsValueTypeHeader} className={classes.tableHeaderCell}>
            Value Type
          </Grid>
          {!isRiskStrat && (
            <Grid item xs={isAdd ? 5 : 1} className={classes.tableHeaderCell}>
              Required?
            </Grid>
          )}
          {!isAdd && (
            <>
              <Grid item xs={2} className={classes.tableHeaderCell}>
                Assessment Date
              </Grid>
              <Grid item xs={2} className={classes.tableHeaderCell}>
                Value
              </Grid>
              <Grid item xs={isWorkingAsGroup ? 1 : 2} className={classes.tableHeaderCell}>
                Reference
              </Grid>
              {isWorkingAsGroup && (
                <Grid item xs={2} className={classes.tableHeaderCell}>
                  Therapy
                </Grid>
              )}
              {!isRiskStrat && (
                <Grid item xs={2} className={classes.tableHeaderLastCell}>
                  <Tooltip title="Not Able to Complete Reason" placement="top-start">
                    <Typography>Not Able to Complete Reason</Typography>
                  </Tooltip>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {!isAdd && (
          <Grid
            container
            justifyContent="left"
            alignItems="center"
            className={classes.applyDateToAllDCRow}
          >
            <Grid item>
              <Typography>Apply the same assesment date to all DC items</Typography>
            </Grid>
            <Grid item>
              <DynamicField
                id="date-field-all"
                property="date-field-all"
                type="date"
                component="date"
                label=""
                noSpace
                onChange={v => setDateChangeAll(v)}
              />
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" onClick={handleDateChangeAll}>
                Apply
              </Button>
            </Grid>
          </Grid>
        )}
        {fieldsIdsWithConditional.map(fieldWithConditional => {
          const therapyNames = [];
          const { id, conditional } = fieldWithConditional;
          const clinicalDataTypeRow = lastRowForClinicalDataTypeId(parseInt(id, 10));
          const field = clinicalDataTypesMap[id];

          // Show the badge if this is a risk stratification DC and this field is RS
          const showRiskStratificationBadge =
            isRiskStratification && riskStratificationDataTypes.includes(Number(field.dataTypeId));
          // Show whitespace if this is a risk stratification DC and but this field is not RS
          const showRiskStratificationWhitespace =
            isRiskStratification && !riskStratificationDataTypes.includes(Number(field.dataTypeId));

          if (isWorkingAsGroup) {
            const allItemsForClinicalDataType = clinicalData.filter(
              item => item.clinicalDataTypeId === Number(id),
            );
            const therapyIds = allItemsForClinicalDataType.map(item => item.therapyId);
            therapyIds.forEach(therapyId => {
              if (
                therapies[therapyId] &&
                !therapyNames.includes(therapies[therapyId].drug_name) &&
                workingAsGroup.includes(therapyId)
              ) {
                therapyNames.push(therapies[therapyId].drug_name);
              }
            });
          }

          const fieldProperties = getFieldProperties(field);
          const fieldValidation = fieldProperties.validation || [];
          const fieldValidationWithLabValueValidation = fieldValidation.concat(
            validators[id].labValueValidator,
          );
          if (field.dataTypeId === FailedTherapies.Id) {
            field.option = medications
              .reduce((acc, currentVal) => {
                if (currentVal.status_code !== MedicationStatus.Active) {
                  acc.push(currentVal.drug_name);
                }
                return acc;
              }, [])
              .join(';');
          }
          return (
            <Grid
              container
              key={id}
              className={cx(conditional ? classes.conditionalField : classes.regularField)}
            >
              <DynamicField
                id={`${id}-id`}
                property={`${id}-id`}
                type="text"
                component="dropdownStatic"
                questionnaire=""
                noSpace
                leftAlignment
                value={field.name}
                gridSize={xsValueTypeDynamicField}
              />
              {showRiskStratificationBadge && (
                <Grid item xs={1}>
                  <Tooltip title="Risk Stratification">
                    <Typography component="span" className={classes.riskStratificationBadge}>
                      RS
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
              {showRiskStratificationWhitespace && (
                <Grid item xs={1}>
                  &nbsp;
                </Grid>
              )}
              {!isRiskStrat && (
                <DynamicField
                  id={`${id}-required`}
                  property={`${id}-required`}
                  type="integer"
                  component="checkbox"
                  label=""
                  noSpace
                  value={Boolean(field.required)}
                  gridSize={1}
                  disabled={!canUncheckRequired}
                />
              )}
              {!isAdd && (
                <>
                  <DynamicField
                    id={`${id}-date`}
                    property={`${id}-date`}
                    type="date"
                    component="date"
                    label=""
                    noSpace
                    gridSize={2}
                    validation={validators[id].assessDateValidator}
                    centerInput
                  />
                  <DynamicField
                    id={`${id}-value`}
                    property={`${id}-value`}
                    type="text"
                    label=""
                    noSpace
                    gridSize={2}
                    suffix={field.unit}
                    {...getFieldProperties(field)}
                    validation={fieldValidationWithLabValueValidation}
                    onButtonClick={buttonFunctionality(
                      field,
                      providerTaskId,
                      isRiskStrat,
                      clinicalDataTypeRow?.id ?? null,
                    )}
                    onPrintButtonClick={printButtonFunctionality(field)}
                    provider={
                      ['list', 'multiselect'].includes(field.type)
                        ? `list-${field.id}`
                        : `data-type-field-${field.id}`
                    }
                    tooltip={field.tooltip}
                    value={field}
                    centerInput={!!workingAsGroup}
                  />
                  <DynamicField
                    id={`${id}-reference`}
                    property={`${id}-reference`}
                    type="text"
                    label=""
                    noSpace
                    gridSize={isWorkingAsGroup ? 1 : 2}
                    component="reference"
                    provider={`references-${field.id}`}
                  />
                  {isWorkingAsGroup && (
                    <Grid item xs={2}>
                      <Typography className={classes.ellipsis}>
                        <Tooltip title={getTherapiesTooltip(therapyNames)} placement="right-start">
                          <span>{therapyNames.join(', ')}</span>
                        </Tooltip>
                      </Typography>
                    </Grid>
                  )}
                  {!isRiskStrat && (
                    <DynamicField
                      id={`${id}-cannot-complete-reason`}
                      property={`${id}-cannot-complete-reason`}
                      type="text"
                      noSpace
                      label=""
                      gridSize={2}
                      visible={form => !form.values[`${id}-value`]}
                    />
                  )}
                </>
              )}
            </Grid>
          );
        })}
      </DynamicForm>
      {recommendations?.length > 0 && (
        <Box className={classes.recommendationsContainer}>
          <Typography className={classes.recommendationsTitle}>Recommendations</Typography>
          <ul className={classes.recommendationsList}>
            {recommendations.map(recommendation => (
              <li key={recommendation.gpi10}>{recommendation.name}</li>
            ))}
          </ul>
        </Box>
      )}
      {questionnaire && (
        <DcQuestionnaire
          clinicalDataItemId={questionnaire.clinicalDataItemsId}
          dataTypeField={questionnaire.dataTypeField}
          patientId={patient.id}
          workingAsGroup={questionnaire.workingAsGroup}
          shouldCreateNewQuestionnaire={shouldCreateNewQuestionnaire}
          setShouldCreateNewQuestionnaire={setShouldCreateNewQuestionnaire}
          missingItemsToCreateState={missingItemsToCreateState}
          newQuestionnaireWasCreated={newQuestionnaireWasCreated}
          setNewQuestionnaireWasCreated={setNewQuestionnaireWasCreated}
          setQuestionnaire={setQuestionnaire}
          updateFormId={updateFormId}
          onClose={(score, submitted) => {
            // score can be 0
            if (score !== undefined && submitted) {
              let multipleScore = null;
              if (questionnaire.dataTypeField.questionnaireId === PROMISS_ID_QUESTIONNAIRE) {
                clinicalData
                  .filter(
                    t => t.clinicalDataTypeId === Number(questionnaire.dataTypeField.dataTypeId),
                  )
                  .forEach(t => {
                    if (t.questionnaire.additionalScoresValues !== null) {
                      multipleScore = `MH: ${
                        JSON.parse(t.questionnaire.additionalScoresValues).mentalPROMISScore ?? 0
                      }, PH: ${
                        JSON.parse(t.questionnaire.additionalScoresValues).physicalPROMISScore ?? 0
                      }`;
                    }
                  });
              }
              dispatch(
                // TODO: 2020-09-18 need to investigate why String(score) works on
                // TODO: questionnaires with 0 value but Number(score) does not
                change(
                  formId,
                  `${questionnaire.dataTypeField.dataTypeId}-value`,
                  multipleScore ?? String(score),
                ),
              );
            }
            setQuestionnaire(null);
            setShouldCreateNewQuestionnaire(null);
            setMissingItemsToCreateState(null);
            setNewQuestionnaireWasCreated(null);
          }}
        />
      )}
      <Modal open={formDisplays ? !!formDisplays.displayAddTherapyModal : null}>
        <div style={getModalStyle()} className={classes.addTherapyModal}>
          <AddPatientTherapy
            forceDocumentOnlyOption
            // eslint-disable-next-line camelcase
            patientId={therapy?.patient_id ? therapy.patient_id : null}
            medSync={patient?.med_sync === MED_SYNC_STATUS.OPT_IN} // eslint-disable-line
            failedTherapyForm
            gpiLookupConfig={{
              disableByColumn: 'gpi',
              disableByValues: (failedTherapy?.dataTypeField?.value || '').split(','),
              highlightByColumn: 'gpi',
              highlightByValues: medications.reduce((acc, curr) => {
                acc.push(curr.gpi.substring(0, 10));
                return acc;
              }, []),
            }}
            handleCloseAddForm={onCloseData => {
              onToggleFormDisplay(false);
              fetchGpis(onCloseData, true).then(res => {
                const gpis = res.data;
                const failedTherapyValue = failedTherapy?.dataTypeField?.value
                  ? new Set(failedTherapy.dataTypeField.value.split(','))
                  : new Set();
                if (gpis && Array.isArray(gpis.drugs) && gpis.drugs.length > 0) {
                  failedTherapyValue.add(gpis.drugs[0].gpi.substr(0, 10));
                  dispatch(
                    change(
                      formId,
                      `${failedTherapy.dataTypeField.dataTypeId}-value`,
                      [...failedTherapyValue].join(),
                    ),
                  );
                  // Send my failed therapy
                  onFailedTherapySubmitted(failedTherapy, [...failedTherapyValue].join());
                }
              });
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
