import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DetailField from 'components/form/field/field';
import React, { useState } from 'react';
import { getStatusByStatusId, getStatusIdByStatusName } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ActionRow from 'components/note/action-row';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { TASK_OUTREACH } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import EditOutreach from './edit-outreach';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';

const OutreachDetail = props => {
  const {
    statuses,
    task,
    classes,
    notes,
    documents,
    serviceGroups,
    tagTypeId,
    tagTypeLabel,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
  } = props;
  const {
    status_id: statusId,
    service_group_id: serviceGroupId,
    fill_cycle_number: fillCycleNumber,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );

  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);

  const [needsByDate] = useFillCycleNumber(therapy.id, fillCycleNumber);

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item lg={3}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                task={task}
              />
            )}
          </Grid>
        </Grid>

        {!displayEdit && (
          <Grid item lg={5} xs={6}>
            <Grid container>
              <TaskDetailForm
                task={{
                  ...task,
                  needsby_date: needsByDate,
                }}
                taskType="OR"
              />
              {selectedStatus === getStatusByStatusId(statusId, statuses) &&
                statuses.length > 0 &&
                (statuses[statusId - statuses[0].id]
                  ? statuses[statusId - statuses[0].id].reason
                  : false) && (
                  <Grid item xs={4}>
                    <DetailField
                      fieldName="Reason"
                      variant="raw"
                      field={
                        statuses[statusId - statuses[0].id]
                          ? statuses[statusId - statuses[0].id].reason
                          : null
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_OUTREACH}
                taskIDPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <EditOutreach
              task={task}
              statuses={statuses}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
              therapy={therapy}
            />
          </Grid>
        )}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <TaskStatusForm
                task={task}
                forceStatusId={selectedStatusId}
                onCancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  const { patient, taskStatuses, lookups } = state;
  return {
    patient,
    taskStatuses,
    lookups,
    users: lookups.users,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(OutreachDetail);
