import cx from 'classnames';
import FormHeader from 'components/form/header/header';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { IPatient } from 'interfaces/redux/IPatient';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { required } from 'components/form/validation/validation';
import { Grid, Typography, Button, CircularProgress, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {
  renderDropdown,
  renderNumberField,
  renderTextField,
  renderPhoneTextField,
} from 'components/form/field/redux-field';
import { parseFullName } from 'utils/name';
import {
  benefitsInvestigationSubmissionFormActions,
  IBenefitsInvestigationSubmissionFormState,
} from 'slices/benefits-investigation-modal-slice';
import { IState } from 'interfaces/redux/IState';
import { BenefitInvestigationStatus } from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';
import { TherapyUtil } from 'utils/therapy-util';
import { Info as InfoIcon, Loop as LoopIcon } from '@mui/icons-material';
import { windowFeatureIsEnabled } from 'config/window-features';
import { useDeepDiffSelector } from 'utils/deep-diff-props';
import { createSelector } from '@reduxjs/toolkit';
import { debounce, isEmpty } from 'lodash';
import { addTaskToState } from '../../../../actions/action-tasks';
import {
  IDur,
  IResponseData,
  IBenefitsInvestigationFormFields,
} from '../../../../interfaces/benefits-investigations';
import { styles } from './benefits-investigation-form-styles';
import { StringUtils } from '../../../../utils/string-utils';
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from '../../../../actions/action-notifications';
import { updateLocalUserPreferencesBiPharmacy } from '../../../../actions/action-user';
import { nameOfFactory } from '../../../../utils/types-util';
import { ILabelValue } from '../../../../interfaces/ILabelValue';
import { getPharmacyInsuranceHeader } from '../../../../services/utils/financials-service';
import { convertToArborDate } from '../../../../models/time/arbor-date';
import { buildQaIdProp } from '../../../../utils/build-qa-id';
import { BenefitsInvestigationService } from '../../../../services/utils/benefits-investigation-service';
import { benefitsInvestigationLatestActions } from '../../../../actions/action-therapy-benefits-investigation-latest';
import { BenefitsInvestigationFields, relationCodes } from '../../../../constants/lists';
import {
  BENEFITS_INVESTIGATION_FORM,
  BENEFITS_INVESTIGATION_DEFAULT_SOFTWARE_CERT,
  PERSON_CODE_DEFAULT_VALUE,
  QRE,
  RELATION_CODE_DEFAULT_VALUE,
} from '../../../../constants';
import ResponseData from './components/response-data/response-data';
import ExpansionSection from '../../../../components/expansion-section';
import ExpandingHeader from './components/expanding-header/expanding-header';
import BenefitsInvestigationDur from './benefits-investigation-dur';
import AutocompleteMinigrid from '../../../../components/form/field/render-minigrid-autocomplete';
import {
  fetchDiagnosisCodes,
  fetchGpis,
  specialtyTypeFormatter,
} from '../../../../services/utils/therapy-service';

const RENDER_WAIT_MS = 250; // low-end of the acceptable feedback range
const RENDER_MAX_WAIT_MS = 1000; // low-end of "feels unresponsive" range; render throttle

const qaIdProp = buildQaIdProp('benefits-investigation-dialog');

const nameOfFormFields = nameOfFactory<IBenefitsInvestigationFormFields>();

/**
 * Props that need to be loaded from the redux state
 */
export interface IStateProps {
  currentValues: Partial<IBenefitsInvestigationFormFields>;
  dispensingPharmacies: any[];
  dispensingPharmaciesLabels: ILabelValue[];
  initialPrice: number | undefined;
  initialValues: Partial<IBenefitsInvestigationFormFields>;
  insuranceOptions: any[];
  patient: IPatient;
  patientFullName: string;
  patientHeadingExpanded: boolean;
  patientId: number;
  pbmInsurances: any[];
  therapy: ITherapy | undefined;
  durTemp: IDur;
  arTaskId?: number;
}

export interface BIProps {
  historicalData?: {
    responseData: Partial<IResponseData>;
    stateValues: Partial<IStateProps>;
    durItems?: IDur[];
    pharmacies?: any[];
  };
  buttonsOverride?: JSX.Element;
  defaultRxNumber?: string | undefined;
  therapy?: ITherapy;
  therapyId?: number;
  arTaskId?: number;
}

interface IBenefitsInvestigationFormProps
  extends InjectedFormProps<IBenefitsInvestigationFormFields>,
    WithStyles<typeof styles>,
    BIProps {}

interface IDaysSupplyChecking {
  daysSupplyInConflict: boolean;
  daysSupplyToolTip: string;
}

export type BenefitsInvestigationFormProps = IStateProps & IBenefitsInvestigationFormProps;

const formName = BENEFITS_INVESTIGATION_FORM;

const getTherapyLabel = (therapy: any) =>
  `${[
    therapy.drug_name,
    therapy.dosage_form ?? '',
    therapy.strength ?? '',
    therapy.strength_unit_of_measure ?? '',
  ]
    .filter(i => !!i)
    .join(' ')} | NDC ${therapy.ndc}`;

export const getQuantityFromState = (
  benefitsInvestigationSubmissionForm: IBenefitsInvestigationSubmissionFormState,
) => {
  const { taskId, dispenseQty } = benefitsInvestigationSubmissionForm;
  if (taskId !== undefined && dispenseQty !== undefined) {
    return dispenseQty[taskId];
  }
  return null;
};

// This is necessary for redux form
export const mapStateToProps = (state: IState, props: BIProps): IStateProps => {
  const { pharmacies } = state;
  const { patient } = state;

  let therapy =
    state.therapies.data && state.benefitsInvestigationSubmissionForm.therapyId
      ? state.therapies.data[state.benefitsInvestigationSubmissionForm.therapyId]
      : undefined;

  if (props.therapyId !== undefined) {
    therapy = state.therapies.data[props.therapyId];
  }
  if (props.therapy !== undefined) {
    therapy = props.therapy;
  }

  const allPbmInsurances = state.financials?.pbm_insurances?.data ?? {};
  const validPbmInsurances = Object.values(allPbmInsurances).filter(
    pbmInsurance => pbmInsurance.valid,
  );

  const therapyNdc = therapy ? getTherapyLabel(therapy) : '';
  const nowFormatted = Moment().format('MM/DD/YYYY');
  const dispensingPharmacies = props.historicalData?.pharmacies || pharmacies.pharmacies || [];
  const dispensingPharmaciesLabels = dispensingPharmacies
    ? dispensingPharmacies.map(pharmacy => ({
        value: pharmacy.id,
        label: pharmacy.name,
      }))
    : [];
  const sortedInsurances =
    validPbmInsurances.sort((a, b) => {
      return a.insurance_type_id - b.insurance_type_id || Number(a.valid) - Number(b.valid);
    }) || [];
  const initialPrice = state.benefitsInvestigationSubmissionForm.drugUnitPricing;

  const preferredAddress =
    (patient.addresses || [])
      .filter(address => !address.deleted)
      .sort((a, b) => a.rank - b.rank)[0] || {};

  let initialValues: Partial<IBenefitsInvestigationFormFields>;
  if (props.historicalData?.stateValues?.initialValues) {
    // Use historical information if we have it, otherwise fill out the form.
    initialValues = props.historicalData.stateValues.initialValues;
  } else {
    let defaultPharmacyId: string | undefined;
    let defaultPharmacyNpi: string | undefined;
    const preferredPharmacyId = state.userPreferences.bi_preferred_pharmacy_id;

    // if there is only one pharmacy, default to that
    if (dispensingPharmacies.length === 1) {
      defaultPharmacyId = dispensingPharmacies[0].id;
      defaultPharmacyNpi = dispensingPharmacies[0].npi;
      // eslint-disable-next-line brace-style
    }
    // if the user has a preferred pharmacy id, select that pharmacy by default
    else if (preferredPharmacyId) {
      const dispensingPharmacy = dispensingPharmacies.find(
        (pharmacy: any) => pharmacy.id === preferredPharmacyId,
      );
      if (dispensingPharmacy) {
        defaultPharmacyId = preferredPharmacyId.toString();
        defaultPharmacyNpi = dispensingPharmacy.npi;
      }
    }

    const quantityValue = getQuantityFromState(state.benefitsInvestigationSubmissionForm);

    initialValues = {
      // patient info
      placeOfResidence: BenefitsInvestigationFields.PlaceOfResidence.find(x => x.default)?.value,
      dob: patient.dob ? convertToArborDate(patient.dob, true).getUtcDate(true) : undefined,
      firstName: patient.first_name,
      lastName: patient.last_name,
      gender: BenefitsInvestigationFields.benefitsInvestigationGenderList.find(
        x => x.value === patient.gender,
      )?.value,
      street: [preferredAddress.line1, preferredAddress.line2].join(' ').toUpperCase(),
      city: ((preferredAddress.city as string) || '').toUpperCase(),
      state: ((preferredAddress.state as string) || '').toUpperCase(),
      zip: preferredAddress.zip,

      // rx info defaults
      rxNo: props.defaultRxNumber,
      drugNameNdc: therapyNdc,
      compoundCode: BenefitsInvestigationFields.CompoundCodes.find(x => x.default)?.value,
      dawCode: BenefitsInvestigationFields.DawCodes.find(x => x.default)?.value,
      dateWritten: nowFormatted,
      prescriptionOriginCode: BenefitsInvestigationFields.PrescriptionOriginCode.find(
        x => x.default,
      )?.value,
      dateOfService: nowFormatted,
      fillNo: therapy?.fill_cycle?.toString(),
      daysSupply: therapy?.days_supply?.toString(),
      // pharmacy info
      dispensingPharmacy: defaultPharmacyId?.toString(),
      dispensingPharmacyNpi: defaultPharmacyNpi,

      // insurance info
      primaryInsuranceId: sortedInsurances[0]?.id.toString(),
      bin: sortedInsurances[0]?.pbm_bin_number,
      pcn: sortedInsurances[0]?.pbm_processor_control_number || '',
      group: sortedInsurances[0]?.pbm_rx_group_number,
      relationCode:
        sortedInsurances[0]?.pbm_relation_code === '0'
          ? RELATION_CODE_DEFAULT_VALUE
          : sortedInsurances[0]?.pbm_relation_code,
      personCode: PERSON_CODE_DEFAULT_VALUE,
      quantity: quantityValue || '',

      // segment defaults
      segmentClarificationCd: BenefitsInvestigationFields.SubClarificationCode.find(x => x.default)
        ?.value,
      segmentOtherCvgCode: BenefitsInvestigationFields.OtherCoverageCode.find(x => x.default)
        ?.value,
      segmentSpecialPkgIndicator: BenefitsInvestigationFields.SpecialPackagingIndicator.find(
        x => x.default,
      )?.value,
      segmentUnitOfMeasure: BenefitsInvestigationFields.UnitOfMeasure.find(x => x.default)?.value,
      segmentLevelOfService: BenefitsInvestigationFields.LevelOfService.find(x => x.default)?.value,
      segmentIntermediaryAuthType: BenefitsInvestigationFields.IntermediaryAuthType.find(
        x => x.default,
      )?.value,
      segmentPaType: BenefitsInvestigationFields.PaType.find(x => x.default)?.value,
      segmentPharmServiceType: BenefitsInvestigationFields.PharmServiceType.find(x => x.default)
        ?.value,
      segmentPlaceOfService: BenefitsInvestigationFields.PlaceOfService.find(x => x.default)?.value,

      // pricing defaults
      pricingBasisOfCostDebt: BenefitsInvestigationFields.BasisOfCostDet.find(x => x.default)
        ?.value,
      pricingIngredientCost: initialPrice?.toString(),
      pricingAwpUnitPrice: initialPrice?.toString(),
      pricingUsualAndCustomary: initialPrice?.toString(),
      pricingGrossAmountDue: initialPrice?.toString(),

      // pharmacy provider segment defaults
      pharmacyProviderProviderType: BenefitsInvestigationFields.PharmacyProviderType.find(
        x => x.default,
      )?.value,
      prescriberPhone: therapy
        ? therapy.prescribing_physician_business_practice_number ||
          therapy.prescribing_physician_authorized_number ||
          therapy.prescribing_physician_business_mailing_number
        : undefined,

      // dur
      durTempReasonForService: BenefitsInvestigationFields.DurReasonForService.find(x => x.default)
        ?.value,
      durTempProfessionalService: BenefitsInvestigationFields.DurProfessionalService.find(
        x => x.default,
      )?.value,
      durTempResultService: BenefitsInvestigationFields.DurResultOfService.find(x => x.default)
        ?.value,
      durTempLevelOfEffort: BenefitsInvestigationFields.DurLevelOfEffort.find(x => x.default)
        ?.value,
      durTempCoAgentIdQualifier: BenefitsInvestigationFields.DurCoAgentIdQualifier.find(
        x => x.default,
      )?.value,
    };
  }

  const selector = formValueSelector(formName);
  const {
    durTempReasonForService,
    durTempProfessionalService,
    durTempResultService,
    durTempLevelOfEffort,
    durTempCoAgentIdQualifier,
    durTempCoAgentId,
  } = selector(
    state,
    nameOfFormFields('durTempReasonForService'),
    nameOfFormFields('durTempProfessionalService'),
    nameOfFormFields('durTempResultService'),
    nameOfFormFields('durTempLevelOfEffort'),
    nameOfFormFields('durTempCoAgentIdQualifier'),
    nameOfFormFields('durTempCoAgentId'),
    nameOfFormFields('primaryInsuranceId'),
  );
  const currentDispensingPharmacy = selector(state, nameOfFormFields('dispensingPharmacy'));
  const currentPrimaryInsuranceId = selector(state, nameOfFormFields('primaryInsuranceId'));
  const currentUsualAndCustomary = selector(state, nameOfFormFields('pricingUsualAndCustomary'));
  const currentGrossAmountDue = selector(state, nameOfFormFields('pricingGrossAmountDue'));
  const currentDispensingFee = selector(state, nameOfFormFields('pricingDispensingFee'));
  const currentOtherAmount = selector(state, nameOfFormFields('pricingOtherAmount'));
  const currentQuantity = selector(state, nameOfFormFields('quantity'));
  const currentStreet = selector(state, nameOfFormFields('street'));
  const currentCity = selector(state, nameOfFormFields('city'));
  const currentState = selector(state, nameOfFormFields('state'));
  const currentDrugNameNdc = selector(state, nameOfFormFields('drugNameNdc'));

  const insuranceOptions = sortedInsurances.map(insurance => {
    return {
      label: getPharmacyInsuranceHeader(insurance),
      value: insurance.id,
    };
  });

  const matchingGenderValue = BenefitsInvestigationFields.benefitsInvestigationGenderList.find(
    // eslint-disable-next-line eqeqeq
    x => x.value == initialValues.gender,
  );
  let patientHeadingExpanded = false;

  if (!matchingGenderValue || initialValues.dob === undefined) {
    patientHeadingExpanded = true;
  }

  return {
    initialValues,
    patient: patient,
    patientId: patient.id,
    pbmInsurances: sortedInsurances,
    therapy: therapy,
    dispensingPharmacies: dispensingPharmacies,
    dispensingPharmaciesLabels: dispensingPharmaciesLabels,
    insuranceOptions,
    initialPrice: initialPrice,
    patientHeadingExpanded,
    patientFullName: props.historicalData?.stateValues.patientFullName || parseFullName(patient),
    currentValues: {
      primaryInsuranceId: currentPrimaryInsuranceId,
      dispensingPharmacy: currentDispensingPharmacy,
      pricingUsualAndCustomary: currentUsualAndCustomary,
      pricingGrossAmountDue: currentGrossAmountDue,
      pricingDispensingFee: currentDispensingFee,
      pricingOtherAmount: currentOtherAmount,
      quantity: currentQuantity,
      street: currentStreet,
      city: currentCity,
      drugNameNdc: currentDrugNameNdc,
      state: currentState,
    },
    durTemp: {
      reasonForService: durTempReasonForService,
      professionalService: durTempProfessionalService,
      resultOfService: durTempResultService,
      levelOfEffort: durTempLevelOfEffort,
      coAgentIdQualifier: durTempCoAgentIdQualifier,
      coAgentId: durTempCoAgentId,
    },
  };
};

const combinePropsWithState = (state: IState, props: BIProps) => {
  return {
    ...props,
    ...mapStateToProps(state, props),
  };
};
const debouncedCombinePropsWithState = debounce(combinePropsWithState, RENDER_WAIT_MS, {
  leading: true,
  maxWait: RENDER_MAX_WAIT_MS,
});

const selectPropsFromState = createSelector(
  [(state: IState) => state, (_state: IState, props: BIProps) => props],
  (state: IState, props: BIProps) =>
    debouncedCombinePropsWithState(state, props) ?? combinePropsWithState(state, props),
);

export const BenefitsInvestigationForm = (props: BenefitsInvestigationFormProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [durItems, setDurItems] = useState<IDur[]>(props.historicalData?.durItems || []);
  const [responseData, setResponseData] = useState<Partial<IResponseData>>({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [runBiLoading, setRunBiLoading] = useState(false);
  const [disableAllFields] = useState<boolean>(props.historicalData != null);
  const [b2StatusExists, setB2StatusExists] = useState(true);
  const [daysSupplyState, setDaysSupplyState] = useState<IDaysSupplyChecking>({
    daysSupplyInConflict: false,
    daysSupplyToolTip: '',
  });
  const hasPhysician = isEmpty(props.therapy?.prescribing_physician_npi);

  // When selected insurance changes, update fields
  useEffect(() => {
    if (props.historicalData?.responseData) {
      setResponseData(props.historicalData.responseData);
    }
  }, [props.historicalData]);

  useEffect(() => {
    const getMostRecentBI = async () => {
      try {
        setRunBiLoading(true);
        if (props.therapy && props.arTaskId === undefined) {
          const service = new BenefitsInvestigationService();
          const result = await service.getMostRecentBISubsmissionByPatientIdTherapyId(
            props.patientId,
            props.therapy.id,
          );
          if (
            result?.[0]?.b1Status === BenefitInvestigationStatus.Paid &&
            result?.[0]?.b2Status == null
          ) {
            setB2StatusExists(false);
          }
        }
      } catch (_error) {
        dispatch(notifyError('Failed fetching most recent BI information'));
      } finally {
        setRunBiLoading(false);
      }
    };
    getMostRecentBI();
  }, [props.arTaskId, props.patientId, props.therapy]);

  useEffect(() => {
    const defaultSoftwareCertId = async () => {
      const service = new BenefitsInvestigationService();
      const latestPaidSoftwareCertId = await service.getLatestPaidSoftwareCertIdByPatientId(
        props.patientId,
      );

      const softwareCertId =
        latestPaidSoftwareCertId || BENEFITS_INVESTIGATION_DEFAULT_SOFTWARE_CERT;
      props.change(nameOfFormFields('softwareCertId'), softwareCertId);
    };
    defaultSoftwareCertId();
  }, [props.currentValues.softwareCertId, props.patientId]);

  useEffect(() => {
    const selectedInsurance = props.pbmInsurances.find(
      x => x.id === props.currentValues.primaryInsuranceId,
    );
    props.change(nameOfFormFields('bin'), selectedInsurance?.pbm_bin_number);
    props.change(nameOfFormFields('pcn'), selectedInsurance?.pbm_processor_control_number);
    props.change(nameOfFormFields('group'), selectedInsurance?.pbm_rx_group_number);
    props.change(nameOfFormFields('relationCode'), selectedInsurance?.pbm_relation_code);
  }, [props.currentValues.primaryInsuranceId]);

  // When selected pharmacy changes, update fields
  useEffect(() => {
    const selectedPharmacy = props.dispensingPharmacies.find(
      x => x.id === props.currentValues.dispensingPharmacy,
    );
    props.change(nameOfFormFields('dispensingPharmacyNpi'), selectedPharmacy?.npi);

    const pharmacyId = Number(props.currentValues.dispensingPharmacy);
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(pharmacyId)) {
      dispatch(updateLocalUserPreferencesBiPharmacy(pharmacyId));
    }
  }, [props.currentValues.dispensingPharmacy]);

  // Update pricing when certain fields change
  useEffect(() => {
    const dispensingFee = props.currentValues.pricingDispensingFee as any;
    const otherAmount = props.currentValues.pricingOtherAmount as any;
    const quantity = props.currentValues.quantity as any;
    const newPrice: string = (
      (quantity ? Number(quantity) : 1) * (props.initialPrice == null ? 1 : props.initialPrice) +
      (dispensingFee ? Number(dispensingFee) : 0) +
      (otherAmount ? Number(otherAmount) : 0)
    ).toFixed(2);

    props.change(nameOfFormFields('pricingGrossAmountDue'), newPrice);
    props.change(nameOfFormFields('pricingUsualAndCustomary'), newPrice);
  }, [props.currentValues.pricingDispensingFee, props.currentValues.pricingOtherAmount]);

  useEffect(() => {
    const dispensingFee = props.currentValues.pricingDispensingFee as any;
    const otherAmount = props.currentValues.pricingOtherAmount as any;
    const quantity = props.currentValues.quantity as any;

    const ingredientCost: number =
      (quantity ? Number(quantity) : 1) * (props.initialPrice == null ? 1 : props.initialPrice);

    const newPrice: string = (
      ingredientCost +
      (dispensingFee ? Number(dispensingFee) : 0) +
      (otherAmount ? Number(otherAmount) : 0)
    ).toFixed(2);

    props.change(nameOfFormFields('pricingIngredientCost'), ingredientCost.toFixed(2));
    props.change(nameOfFormFields('pricingUsualAndCustomary'), newPrice);
    props.change(nameOfFormFields('pricingGrossAmountDue'), newPrice);
  }, [props.currentValues.quantity]);

  useEffect(() => {
    handleDaysSupplyConflict(props.therapy?.days_supply ?? '');
  }, []);

  const addDurItem = (item: IDur) => {
    setDurItems(prevState => prevState.concat(item));
  };

  const resetDurFields = (props: React.PropsWithChildren<BenefitsInvestigationFormProps>) => {
    props.change(
      nameOfFormFields('durTempReasonForService'),
      props.initialValues.durTempReasonForService,
    );
    props.change(
      nameOfFormFields('durTempProfessionalService'),
      props.initialValues.durTempProfessionalService,
    );
    props.change(
      nameOfFormFields('durTempResultService'),
      props.initialValues.durTempResultService,
    );
    props.change(
      nameOfFormFields('durTempLevelOfEffort'),
      props.initialValues.durTempLevelOfEffort,
    );
    props.change(
      nameOfFormFields('durTempCoAgentIdQualifier'),
      props.initialValues.durTempCoAgentIdQualifier,
    );
    props.change(nameOfFormFields('durTempCoAgentId'), props.initialValues.durTempCoAgentId);
  };

  const resetState = () => {
    setResponseData({});
    setDurItems([]);
  };

  const onClose = () => {
    resetState();
    dispatch(benefitsInvestigationSubmissionFormActions.close());
    if (
      typeof props.currentValues.drugNameNdc !== 'string' &&
      props.currentValues.drugNameNdc?.ndc !== props.therapy?.ndc
    ) {
      dispatch(
        notifyWarning(
          `Please, remember to update the therapy NDC to ${props.currentValues.drugNameNdc?.ndc}`,
        ),
      );
    }
  };

  const handleDaysSupplyConflict = (daysSupplyValue: number | string): void => {
    if (props.therapy && props.patient) {
      const daysSupplyChecking = TherapyUtil.checkDaysSupply(
        daysSupplyValue,
        props.therapy,
        props.patient,
      );
      setDaysSupplyState(daysSupplyChecking);
    }
  };

  const formatPersonCode = (personCode: string) => personCode.padStart(3, '0');

  const updateBenefitsInvestigation = async () => {
    try {
      setSaveLoading(true);
      const service = new BenefitsInvestigationService();
      if (responseData.internalId) {
        const result = await service.updateBenefitsInvestigationSaved(
          props.patientId,
          responseData.internalId,
          true,
        );
        if (result) {
          dispatch(benefitsInvestigationLatestActions.updateByTherapyId(result));
          onClose();
        }
        dispatch(notifySuccess('Benefit Investigation successfully saved'));
      }
    } catch (error) {
      dispatch(notifyError('Failed trying to save Benefit Investigation'));
    } finally {
      setSaveLoading(false);
    }
  };

  const runBenefitsInvestigation = async (formData: IBenefitsInvestigationFormFields) => {
    try {
      if (props.therapy) {
        setRunBiLoading(true);
        const service = new BenefitsInvestigationService();
        formData.personCode = formData.personCode ? formatPersonCode(formData.personCode) : null;
        formData.saved = false;
        const drugNameNdc =
          typeof formData.drugNameNdc === 'string'
            ? formData.drugNameNdc
            : getTherapyLabel(formData.drugNameNdc);
        const ndc =
          typeof formData.drugNameNdc === 'string' ? props.therapy.ndc : formData.drugNameNdc.ndc;
        let result;
        if (props.arTaskId !== undefined) {
          result = await service.getBiResponseFromAr(
            props.patientId,
            props.arTaskId,
            {
              ...formData,
              icdCode: formData.icdCode?.code ?? props.therapy?.diagnosis_code,
              physicianNpi: props.therapy?.prescribing_physician_npi,
              drugNameNdc,
              ndc,
            },
            durItems,
          );
        } else {
          result = await service.getResponse(
            props.patientId,
            props.therapy.id,
            props.therapy,
            {
              ...formData,
              drugNameNdc,
              ndc,
              icdCode: formData.icdCode?.code ?? props.therapy?.diagnosis_code,
            },
            durItems,
          );
        }

        setResponseData(result.converted);

        if (result.newIncident) {
          dispatch(addTaskToState(result.newIncident.id, QRE, result.newIncident));
        }
        if (result?.b1Status === BenefitInvestigationStatus.Paid && result?.b2Status == null) {
          setB2StatusExists(false);
        }
      }
    } catch (error) {
      dispatch(notifyError('Failed running Benefit Investigation'));
    } finally {
      setRunBiLoading(false);
    }
  };

  const renderPharmacySection = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography className={classes.subHeader}>Pharmacy</Typography>
        </Grid>
        <Grid item xs={12} {...qaIdProp('dispensing-pharmacy')}>
          <Field
            name={nameOfFormFields('dispensingPharmacy')}
            label="Dispensing Pharmacy *"
            component={renderDropdown}
            fields={props.dispensingPharmaciesLabels}
            validate={[required]}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={nameOfFormFields('dispensingPharmacyNpi')}
            label="NPI"
            component={renderTextField}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={nameOfFormFields('dateOfService')}
            label="Date of Service *"
            component={renderDatePicker}
            validate={[required]}
            disabled={disableAllFields}
          />
        </Grid>
      </>
    );
  };

  const handleFetchGpisOptions = (searchText: string) => {
    // eslint-disable-next-line no-confusing-arrow
    return fetchGpis(searchText).then((result: any) =>
      result.data
        ? result.data.drugs.map((therapy: any) => ({
            ...therapy,
            id: therapy.ndc,
            label: getTherapyLabel(therapy),
            disabled: therapy.gpi.substring(0, 10) !== props.therapy?.gpi?.substring(0, 10),
          }))
        : [],
    );
  };

  const renderRxInformationSection = () => {
    return (
      <>
        <Grid item xs={12} className={classes.headerWrapper}>
          <Typography className={classes.header}>Rx Information</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Field
              name={nameOfFormFields('drugNameNdc')}
              label="NDC *"
              component={AutocompleteMinigrid}
              validate={[required]}
              fetchOptions={handleFetchGpisOptions}
              hint="Search by Therapy or GPI or NDC"
              columnsToShow={{
                gpi: 'GPI',
                drug_name: 'Drug Name',
                dosage_form_description: 'Form',
                dose: 'Dose',
                ldd: 'LDD',
                specialty_type: 'Specialty Type',
                ndc: 'NDC',
              }}
              disabled={disableAllFields}
              minSearchLength={2}
              defaultValue={props.initialValues?.drugNameNdc}
              qaId={nameOfFormFields('drugNameNdc')}
              valueFormatter={specialtyTypeFormatter}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('rxNo')}
            label="Rx No. *"
            component={renderTextField}
            validate={[required]}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('quantity')}
            label="Quantity *"
            component={renderNumberField}
            validate={[required]}
            disabled={disableAllFields}
            decimal
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('fillNo')}
            label="Fill No. *"
            component={renderNumberField}
            validate={[required]}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={
            windowFeatureIsEnabled('med_sync') && daysSupplyState.daysSupplyInConflict
              ? classes.daySupplyConflict
              : ''
          }
        >
          <Field
            name={nameOfFormFields('daysSupply')}
            label="Days Supply *"
            component={renderNumberField}
            validate={[required]}
            disabled={disableAllFields}
            onChange={(e: any) => handleDaysSupplyConflict(e.target.value)}
          />
          {windowFeatureIsEnabled('med_sync') && daysSupplyState.daysSupplyToolTip.length > 0 && (
            <Tooltip title={daysSupplyState.daysSupplyToolTip} placement="right-start">
              {daysSupplyState.daysSupplyInConflict ? (
                <InfoIcon className={classes.daysSupplyInfo} />
              ) : (
                <LoopIcon className={classes.daysSupplyInfo} />
              )}
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={6}>
          <Field
            name={nameOfFormFields('compoundCode')}
            label="Compound Code"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.CompoundCodes}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={nameOfFormFields('dawCode')}
            label="DAW Code"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.DawCodes}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('dateWritten')}
            label="Date Written"
            component={renderDatePicker}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('refillsAuthorized')}
            label="Refills Authorized"
            component={renderNumberField}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={nameOfFormFields('prescriptionOriginCode')}
            label="Prescription Origin Cd"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.PrescriptionOriginCode}
            disabled={disableAllFields}
          />
        </Grid>
      </>
    );
  };

  const renderInsuranceSection = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography className={classes.subHeader}>Insurance</Typography>
        </Grid>
        {props.historicalData == null ? (
          <Grid item xs={12}>
            <Field
              label="Insurance *"
              name={nameOfFormFields('primaryInsuranceId')}
              component={renderDropdown}
              className={classes.input}
              validate={required}
              fields={props.insuranceOptions}
              disabled={disableAllFields}
            />
          </Grid>
        ) : null}
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('bin')}
            label="BIN"
            component={renderTextField}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('pcn')}
            label="PCN"
            component={renderTextField}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('group')}
            label="Group"
            component={renderTextField}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('personCode')}
            label="Person Code"
            component={renderNumberField}
            isNumericString
            allowLeadingZeros
            decimalScale={0}
            isAllowed={(values: any) => {
              const { formattedValue } = values;
              return formattedValue.length < 4;
            }}
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('relationCode')}
            label="Relation Code"
            component={renderDropdown}
            fields={relationCodes}
            disabled={disableAllFields}
          />
        </Grid>
      </>
    );
  };

  const renderDurSection = () => {
    return (
      <ExpansionSection title="DUR">
        <BenefitsInvestigationDur
          onAdd={() => {
            addDurItem({
              reasonForService: props.durTemp.reasonForService,
              professionalService: props.durTemp.professionalService,
              resultOfService: props.durTemp.resultOfService,
              levelOfEffort: props.durTemp.levelOfEffort,
              coAgentIdQualifier: props.durTemp.coAgentIdQualifier,
              coAgentId: props.durTemp.coAgentId,
            });
            resetDurFields(props);
          }}
          reasonForServiceFieldName={nameOfFormFields('durTempReasonForService')}
          professionalServiceFieldName={nameOfFormFields('durTempProfessionalService')}
          resultOfServiceFieldName={nameOfFormFields('durTempResultService')}
          levelOfEffortFieldName={nameOfFormFields('durTempLevelOfEffort')}
          coAgentIdQualifierFieldName={nameOfFormFields('durTempCoAgentIdQualifier')}
          coAgentIdFieldName={nameOfFormFields('durTempCoAgentId')}
          durItems={durItems}
          disableAllFields={disableAllFields}
        />
      </ExpansionSection>
    );
  };

  const renderPharmacyProviderSection = () => {
    return (
      <ExpansionSection title="Pharmacy Provider Segment">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pharmacyProviderProviderType')}
              label="Provider Type"
              component={renderDropdown}
              fields={BenefitsInvestigationFields.PharmacyProviderType}
              disabled={disableAllFields}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pharmacyProviderProdiverId')}
              label="Provider ID"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
        </Grid>
      </ExpansionSection>
    );
  };

  const renderPricingSection = () => {
    return (
      <ExpansionSection title="Pricing Segment">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingIngredientCost')}
              label="Ingredient Cost"
              component={renderNumberField}
              width="100%"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingAwpUnitPrice')}
              label="AWP Unit Price"
              component={renderNumberField}
              width="100%"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingUsualAndCustomary')}
              label="Usual and Customary"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingGrossAmountDue')}
              label="Gross Amount Due"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingDispensingFee')}
              label="Dispensing Fee"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingOtherAmount')}
              label="Other Amount"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingPtPaidAmtSubmitted')}
              label="PT Paid Amt Submitted"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('pricingBasisOfCostDebt')}
              label="Basis of Cost Det"
              component={renderDropdown}
              fields={BenefitsInvestigationFields.BasisOfCostDet}
              disabled={disableAllFields}
            />
          </Grid>
        </Grid>
      </ExpansionSection>
    );
  };

  const handleFetchDiagnosisOptions = async (searchText: string) => {
    const result = await fetchDiagnosisCodes(searchText);
    return result.data
      ? result.data.icd10s.map((icd10: { code: string; description: string }) => ({
          ...icd10,
          id: icd10.code,
          label: icd10.description,
        }))
      : [];
  };

  const renderClinicalSection = () => {
    return (
      <ExpansionSection title="Clinical Segment">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              name={nameOfFormFields('icdCode')}
              label="Diagnosis Code"
              component={AutocompleteMinigrid}
              fetchOptions={handleFetchDiagnosisOptions}
              hint="Search by Diagnosis Name or Code"
              defaultValue={props.initialValues?.icdCode || props.therapy?.diagnosis_code}
              disabled={disableAllFields}
              labelField="code"
              columnsToShow={{
                code: 'Code',
                description: 'Description',
              }}
              clearable
            />
          </Grid>
        </Grid>
      </ExpansionSection>
    );
  };

  const renderSegmentInformationSection = () => {
    return (
      <ExpansionSection title="Segment Information">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              name={nameOfFormFields('segmentClarificationCd')}
              label="Sub Clarification Cd"
              component={renderDropdown}
              isMulti
              fields={BenefitsInvestigationFields.SubClarificationCode}
              disabled={disableAllFields}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={nameOfFormFields('segmentOtherCvgCode')}
              label="Other Cvg Code"
              component={renderDropdown}
              fields={BenefitsInvestigationFields.OtherCoverageCode}
              helpMessage="Note: This value will be sent to the primary payor"
              disabled={disableAllFields}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentSpecialPkgIndicator')}
              label="Special Pkg Indicator"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.SpecialPackagingIndicator}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentUnitOfMeasure')}
              label="Unit of Measure"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.UnitOfMeasure}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentLevelOfService')}
              label="Level of Service"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.LevelOfService}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentIntermediaryAuthType')}
              label="Intermediary Auth Type"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.IntermediaryAuthType}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentIntermediaryAuthId')}
              label="Intermediary Auth ID"
              component={renderTextField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentScheduledRxIdNo')}
              label="Scheduled Rx ID No."
              component={renderTextField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentPaType')}
              label="PA Type"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.PaType}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentPaNumber')}
              label="PA Number"
              component={renderNumberField}
              disabled={disableAllFields}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentPharmServiceType')}
              label="Pharm Service Type"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.PharmServiceType}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={nameOfFormFields('segmentPlaceOfService')}
              label="Place Of Service"
              component={renderDropdown}
              disabled={disableAllFields}
              fields={BenefitsInvestigationFields.PlaceOfService}
            />
          </Grid>
        </Grid>
      </ExpansionSection>
    );
  };

  const renderButtons = (): JSX.Element => {
    if (props.buttonsOverride) {
      return props.buttonsOverride;
    }
    return (
      <Grid
        data-qa-id="handle-submit-confirmation-panel"
        className={classes.confirmationPanel}
        container
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item>
          <Button
            data-qa-id="panel-cancel-button"
            name="add_therapy_cancel_button"
            type="button"
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-qa-id="panel-run-benefit-investigation-button"
            name="benefits_investigation_run_button"
            onClick={props.handleSubmit(runBenefitsInvestigation)}
            className={classes.benefitsInvestigationSaveButton}
            type="button"
            variant="contained"
            color="primary"
            disabled={runBiLoading || saveLoading || !b2StatusExists || hasPhysician}
          >
            RUN NEW BI
            {runBiLoading && (
              <CircularProgress
                data-qa-id="loading-run-benefit-investigation-button"
                className={classes.loading}
                size={20}
                variant="indeterminate"
              />
            )}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-qa-id="panel-save-benefit-investigation-button"
            name="add_therapy_submit_button"
            type="button"
            onClick={updateBenefitsInvestigation}
            disabled={saveLoading || runBiLoading || !responseData.internalId}
            color="primary"
            variant="contained"
            className={classes.benefitsInvestigationSaveButton}
          >
            SAVE RESPONSE
            {saveLoading && (
              <CircularProgress
                data-qa-id="loading-save-benefit-investigation-button"
                className={classes.loading}
                size={20}
                variant="indeterminate"
              />
            )}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <form data-qa-id="form-benefits-investigation-dialog" autoComplete="off">
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <FormHeader header="Benefit Investigation" />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} className={classes.scrollingColumn}>
              <Grid container>
                <Grid item xs={12} className={classes.headerGridItem}>
                  <ExpandingHeader
                    defaultExpanded={props.patientHeadingExpanded}
                    label="Patient"
                    text={props.patientFullName}
                    expandable
                    fieldsAndLabels={[
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('firstName')}
                            label="First Name *"
                            component={renderTextField}
                            width="100%"
                            validate={[required]}
                            normalize={StringUtils.toUpper}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'firstName',
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('lastName')}
                            label="Last Name *"
                            component={renderTextField}
                            width="100%"
                            validate={[required]}
                            normalize={StringUtils.toUpper}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'lastName',
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('dob')}
                            label="DOB *"
                            component={renderDatePicker}
                            width="100%"
                            validate={[required]}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'dob',
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('gender')}
                            label="Gender *"
                            component={renderDropdown}
                            fields={BenefitsInvestigationFields.benefitsInvestigationGenderList}
                            validate={[required]}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'gender',
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('placeOfResidence')}
                            label="Patient Residence"
                            component={renderDropdown}
                            disabled={disableAllFields}
                            fields={BenefitsInvestigationFields.PlaceOfResidence}
                          />
                        ),
                        key: 'placeOfResidence',
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('street')}
                            label="Street"
                            component={renderTextField}
                            normalize={StringUtils.toUpper}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'street',
                        gridSize: 5,
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('city')}
                            label="City"
                            component={renderTextField}
                            normalize={StringUtils.toUpper}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'city',
                        gridSize: 3,
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('state')}
                            label="State"
                            component={renderTextField}
                            normalize={StringUtils.toUpper}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'state',
                        gridSize: 2,
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('zip')}
                            label="Zip"
                            component={renderTextField}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'Zip',
                        gridSize: 2,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} className={classes.headerGridItem}>
                  <ExpandingHeader
                    label="Prescriber"
                    text={
                      props.therapy?.prescribing_physician_name ||
                      props.initialValues?.prescriberHistory?.prescriberName
                    }
                    expandable
                    fieldsAndLabels={[
                      {
                        label: 'Prescriber Npi',
                        value:
                          props.therapy?.prescribing_physician_npi ||
                          props.initialValues?.prescriberHistory?.prescriberNpi,
                      },
                      {
                        label: 'Prescribing Clinic',
                        value: props.therapy?.referring_clinic_name,
                      },
                      {
                        label: 'Managing Clinic',
                        value:
                          props.therapy?.managing_clinic_name ||
                          props.initialValues.prescriberHistory?.managingClinic,
                      },
                      {
                        label: 'Service Group',
                        value:
                          props.therapy?.service_groups_name ||
                          props.initialValues.prescriberHistory?.serviceGroup,
                      },
                      {
                        field: (
                          <Field
                            name={nameOfFormFields('prescriberPhone')}
                            label="Prescriber Phone"
                            component={renderPhoneTextField}
                            disabled={disableAllFields}
                          />
                        ),
                        key: 'prescriberPhone',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <>
                <Grid
                  container
                  alignItems="center"
                  spacing={3}
                  className={classes.spacingContainer}
                >
                  {renderRxInformationSection()}
                  {renderPharmacySection()}
                  {renderInsuranceSection()}

                  <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography className={classes.header}>Additional Options</Typography>
                  </Grid>

                  {renderSegmentInformationSection()}
                  {renderDurSection()}
                  {renderPharmacyProviderSection()}
                  {renderPricingSection()}
                  {renderClinicalSection()}

                  <Grid item xs={12}>
                    <Field
                      name={nameOfFormFields('softwareCertId')}
                      label="Software Cert Id *"
                      component={renderTextField}
                      disabled={disableAllFields}
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.softwareCertMsgItem}>
                    <Typography variant="caption">
                      Note: if plan rejects with reject code &quot;AK&quot; try the following:
                      <br />
                      Argus Plans: 601DN30Y
                      <br />
                      Caremark &amp; Aetna Plans: D012000113
                      <br />
                      Medco Plans: ESI
                      <br />
                      Omnisys Plans: 522080
                    </Typography>
                  </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item xs={4} className={cx(classes.scrollingColumn, classes.rightColumn)}>
              <ResponseData {...responseData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isEmpty(props.therapy?.prescribing_physician_npi) && isEmpty(props.historicalData) && (
            <Typography className={classes.errorMissingPhysician}>
              Physician is required to run BI
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} className={classes.confirmationContainer}>
          {renderButtons()}
        </Grid>
      </Grid>
    </form>
  );
};

const BenefitsInvestigationFormComposed = compose<BenefitsInvestigationFormProps, BIProps>(
  withStyles(styles),
  reduxForm({ form: formName, enableReinitialize: false }),
)(BenefitsInvestigationForm);

const BenefitsInvestigationFormWrapper = (originalProps: BIProps) => {
  const props = useDeepDiffSelector(state => selectPropsFromState(state, originalProps));
  return <BenefitsInvestigationFormComposed {...props} />;
};
export default BenefitsInvestigationFormWrapper;
